:root {
  --c: 5.5rem;
  --c2: 12.5rem;
  --c3: 19.5rem;
  --c4: 26.5rem;
  --c5: 33.5rem;
  --c6: 40.5rem;
  --c7: 47.5rem;
  --c8: 54.5rem;
  --c9: 61.5rem;
  --c10: 68.5rem;
  --c11: 75.5rem;
  --c12: 82.5rem;
  --c-outer: 85.51rem;

  --w: var(--c-outer);
  --w-content: var(--c10);
  --w-prose: var(--c8);
  --w-max: 94.5rem;

  --p: 1.5rem;
  --gap: 1.5rem;

  --spacing-s: 0.5em;
  --spacing: 1.5em;

  --padding: 3rem;
  --padding-m: 4rem;

  --margin: 6rem;
  --margin-m: 8rem;
  --margin-l: 10rem;
  --margin-xl: 12.5rem;

  --icon-size: 2em;
  /* --icon-color: var(--black); keep the currentColor default */

  --interact-size: max(12px, 1.5rem);
  --screening-animation-time: 0.5s;

  --z-header: 20;
  --z-main-gradient: 18;
  --z-footer: 19;
  --z-gallery-gradient: 10;
  --z-gallery-control: 12;
  --z-modal: 50;
  --z-mobile-menu: 90;
  --z-menu-toggle: 91;
  --z-menu-cta: 93;
}

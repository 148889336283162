.faq__group {
  margin-top: var(--spacing);
}

.faq__details {
  display: block;
  border-bottom: 1px solid;
}

h3.faq__question {
  margin: 0 var(--icon-size) 0 0;
  text-align: left;
}

.prose h2.faq__group {
  margin-top: var(--margin);

  @media (--media-md) {
    margin-top: var(--margin-m);
  }
}

.faq__summary {
  padding: 1em 0;
  position: relative;
}

.expand-icon__container {
  --icon-size: 2em;

  position: absolute;
  top: calc((100% - var(--icon-size)) / 2);
  right: 0;

  transition: opacity 0.3s ease-out;
}
.expand-more {
  opacity: 1;
}
.expand-less {
  opacity: 0;
}
details[open] .expand-icon__container.expand-more {
  opacity: 0;
}
details[open] .expand-icon__container.expand-less {
  opacity: 1;
}

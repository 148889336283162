:root {
  --lightblue: #94daff;
  --violet: #402a95;
  --turquoise-light: #0ff2c9;
  --turquoise: #10bca6;

  --black: #181818;
  --white: #ffffff;

  --accent: var(--violet);
  --darker: #1a123e;
  --accent-fg: var(--white);

  --footer-bg: var(--violet);
  --hero-color: var(--white);

  --header-color: var(--turquoise);
  --menu-bg: var(--lightblue);
  --menu-fg: var(--violet);
  --menu-button-bg: var(--violet);
  --menu-button-color: var(--lightblue);
}

h1 {
  color: var(--violet);
}

.accented {
  --button-color: var(--accent);
  --button-bg: var(--accent-fg);

  h1 {
    color: var(--white);
  }
}

.cta-container .button {
  --button-bg: var(--lightblue);
  --button-color: var(--violet);

  @media (--media-sm-only) {
    padding: 0.9rem;
  }
}

.nav-dropdown > .button {
  --button-bg: var(--turquoise);
  --button-color: var(--white);

  .icon {
    transform: rotate(90deg);
  }

  &[aria-expanded=true] .icon{
    transform: rotate(-90deg);
  }
}

.nav-dropdown__dropdown .button {
  --button-bg: var(--lightblue);
  --button-color: var(--violet);

  padding: 0.5rem 3rem;
}

.hero-container {
  border: var(--p) var(--white) solid;
  overflow: initial;
}

.slogan {
  left: calc(-1 * 2 * var(--p));
  bottom: min(7.5rem,33%);

  span {
    padding: 0 0.5em 0.15em 0.75em;
    position: relative;
    overflow: visible;
    line-height: 0.8;
    font-size: 2.5rem;

    @media (--media-md) {
      font-size: 4rem;
    }

    @media (--media-lg) {
      font-size: 6rem;
    }
  }
}

.logo-container {
  position: relative;
}

header .logo-image {
  position: absolute;
  max-width: 6rem;
  max-height: 6rem;

  @media (--media-md) {
    max-width: 9rem;
    max-height: 9rem;
  }
}

.engagement-box {
  --fg: var(--white);
}

.engagement-box:nth-child(4n + 1) {
}
.engagement-box:nth-child(4n + 2) {
  --accent: var(--turquoise);
}
.engagement-box:nth-child(4n + 3) {
  --accent: var(--lightblue);
  --fg: var(--black);
  --button-color: var(--black);
}
.engagement-box:nth-child(4n + 4) {
  --accent: var(--darker);
}

.committees {
  --accent: var(--lightblue);
  --accent-fg: var(--black);
}

.featured-testimonials-lead, .plugin--testimonials-search {
  display: none;
}

.header {
  --_padding: var(--header-padding, 20px);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100vw;

  padding-top: var(--_padding);
  padding-bottom: var(--_padding);

  z-index: var(--z-header);

  background-color: var(--header-bg, var(--accent));
  color: var(--header-color, var(--fg));

  transition: background-color 0.3s ease-out;

  .hero--top & {
    background-color: transparent;
  }

  @media (--media-sm-only) {
    background-color: var(--header-bg, var(--accent));
  }
}

.header-container {
  position: relative;
  height: 3rem;
  display: flex;
  align-items: center;

  transition: height 0.3s ease-out;

  @media (--media-md) {
    .hero--top & {
      height: 8em;
    }
  }
}

.header-container {
  justify-content: space-between;
  gap: var(--spacing);
  padding-right: calc(var(--_padding) * 1 + 3em);

  .button {
    --button-bg: var(--bg);
    --button-color: var(--fg);

    padding: 1rem 3rem;
    font-size: var(--interact-size);
  }
}

.toggle-container {
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  z-index: var(--z-menu-toggle);
  width: 3em;
  display: grid;
  place-items: center;
}

header .logo-image {
  max-height: 100%;
}

.logo-container {
  flex: 0 0 auto;
  height: 100%;
  margin-right: auto;
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;
  place-items: center;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;

  background: var(--menu-bg);
  color: var(--menu-fg);
  z-index: var(--z-mobile-menu);

  overflow-y: auto;

  padding-top: 2rem;
  padding-bottom: 4rem;

  .nav-toggle {
    margin-left: auto;
  }

  .nav-list-item {
    --button-color: var(--menu-button-color);
    --button-bg: var(--menu-button-bg);
  }

  .nav-link {
    font-weight: bold;
  }
}

.container--mobile-menu {
  position: relative;
}

.container--canton-outline {
  pointer-events: none;
  grid-row: 1;
  grid-column: 1;
  display: grid;
  place-items: center;

  svg {
    max-height: calc(80vh - 6rem); /* -6rem is mobile-menu top/bottom margins */
    max-width: 100%;
  }
}

.container--mobile-menu {
  grid-row: 1;
  grid-column: 1;
}

.cta-container {
  flex: 0 1 auto;

  span {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: 30rem) {
    .button {
      font-size: 1rem;
      padding: 1rem;
      gap: 0.5rem;
    }
  }
}

.menu-is-open .mobile-menu {
  display: grid;
}

.nav-toggle {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;

  display: flex;
  position: relative;
  z-index: var(--z-menu-toggle);

  /* Necessary because of https://developer.apple.com/forums/thread/690529 */
  color: currentColor;
}

[data-menu-toggle="close"] {
  display: none;
  color: var(--menu-fg);
}

.menu-is-open {
  [data-menu-toggle="close"] {
    display: revert;
  }
  [data-menu-toggle="open"] {
    display: none;
  }
}

.main-nav {
  .nav-list {
    justify-content: right;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.nav-list {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-height: 800px) {
    gap: 1em;
  }
}

.language-nav {
  .nav-list {
    margin-top: var(--margin-m);
    justify-content: center;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.mobile-title {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin: 0.5em 1em 1.5em;
}

.mobile-nav {
  font-size: 1.75rem;

  .nav-list {
    flex-direction: column;
  }

  .nav-list--sites {
    flex-direction: row;
    justify-content: center;
  }
}

.mobile-nav--sites {
  margin-top: 2em;
}

.hero {
  .logo-image {
    opacity: 1;
  }

  &.hero--top .logo-image {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}

.nav-dropdown {
  display: none;
  position: relative;

  @media (--media-md) {
    display: block;
  }
}

.nav-list--sites {
  @media (--media-md) {
    display: none;
  }
}

.nav-dropdown__dropdown {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  max-height: calc(100vh - 6rem);
  gap: 2px;
  padding-top: 2px;
  width: 100%;

  a {
    min-width: 10rem;
  }
}

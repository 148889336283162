@font-face {
  font-family: GTAmerica;
  font-weight: 400;
  font-style: normal;
  src:
    local("GT-America-Regular"),
    url(../fonts/GTAmerica/GT-America-Regular.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: GTAmerica;
  font-weight: 700;
  font-style: normal;
  src:
    local("Poppins-Bold"),
    url(../fonts/GTAmerica/GT-America-Standard-Bold.woff2) format("woff2");
  font-display: swap;
}
body {
  font-family: GTAmerica, Helvetica, Arial, sans-serif;
}

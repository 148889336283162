html {
  font-size: clamp(
    0.8rem,
    1vw,
    1rem
  ); /*16px font-size < 1280 viewport width, 18px font-size @ 1440vw*/
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  line-height: 1.4;

  /* font-family is defined in ../fonts/* */
  font-size: 1.25rem;
}

.prose {
  h1 {
    font-size: 2.5rem;
    line-height: 1.25;
    margin-top: var(--margin);
    margin-bottom: 1.25em;
    max-width: var(--w-prose);
    hyphens: auto;
    overflow-wrap: break-word; /* Only break words if single words do not fit on a line */

    @media (--media-md) {
      font-size: 3.25rem;
      margin-top: var(--margin-l);
    }
  }

  h2,
  h3 {
    line-height: 1.25;
    max-width: var(--w-prose);
    margin-top: 3em;
    margin-bottom: var(--spacing);
    hyphens: auto;
    overflow-wrap: break-word; /* Only break words if single words do not fit on a line */
  }

  p,
  .button,
  figure,
  ul,
  ol,
  table {
    max-width: var(--w-prose);
    margin-bottom: var(--spacing);
  }

  &.plugin,
  .plugin & {
    .button {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 1.5rem;
  }

  a:not([class]) {
    color: var(--link-color);
  }
}

figcaption {
  margin: 0.5rem 0;
}

figcaption,
small {
  font-size: 1rem;
}

.lead {
  font-size: 1.1em;
  font-weight: bold;
}
